import React from 'react';
import Hero from './views/Hero';
import './App.css';
import CustomHero from "./views/CustomHero";
import BondDetails from "./views/BondDetails";
import Footer from "./views/Footer";
import withRoot from "./withRoot";

function App() {
  return (
    <div className="App">
        <Hero />
        <CustomHero />
        <BondDetails />
        <Footer />
    </div>
  );
}

export default withRoot(App);
