import * as React from 'react'
import Typography from "../components/Typography";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import Container from "@mui/material/Container";

const SectionRoot = styled('section')(({ theme }) => ({
    color: theme.palette.common.white,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
        height: '20vh',
        minHeight: 250,
        maxHeight: 1300,
    },
}));

const Background = styled('div')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    zIndex: -2,
});

export default function CustomHero() {
    return (
        <SectionRoot>
            <Container
                sx={{
                    mt: 3,
                    mb: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography color="inherit" align="center" variant="h3">
                    NO NEW TAXES
                </Typography>
                <Box
                    sx={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        backgroundColor: 'common.red',
                        opacity: 0.5,
                        zIndex: -1,
                    }}
                />
                <Background sx={{
                    backgroundColor: '#012596',
                    backgroundPosition: 'center',
                }} />
            </Container>
        </SectionRoot>
    )
}