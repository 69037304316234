import * as React from 'react';
import Typography from '../components/Typography';
import HeroLayout from './HeroLayout';


export default function Hero() {
    return (
        <HeroLayout
            sxBackground={{
                backgroundColor: '#af0111',
                backgroundPosition: 'center',
            }}
        >
            <Typography color="inherit" align="center" variant="h1" marked="center">
                Vote NO
            </Typography>
            <Typography
                color="inherit"
                align="center"
                variant="h3"
                sx={{ mb: 2, mt: 2}}
            >
                MAY 7, 2024
            </Typography>
            <Typography
                color="inherit"
                align="center"
                variant="h4"
                sx={{ mb: 2, mt: 2}}
            >
                St. Joseph Public Schools Bond
            </Typography>
            <Typography
                color="inherit"
                align="center"
                variant="h4"
                sx={{ mb: 2, mt: 2}}
            >
                $98,500,000
            </Typography>
        </HeroLayout>
    );
}